$(window).ready(function () {
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - 0
                }, 1000);
                return false;
            }
        }
    });

    $("button.burger").click(function(){
        $(this).toggleClass("active");
        $('.menu__wrap nav').toggleClass("active");
        $('body').toggleClass("over_hide");
    });
    $("nav ul li a").click(function(){
        $('button.burger').removeClass("active");
        $('.menu__wrap nav').removeClass("active");
        $('body').removeClass("over_hide");
    });

    // Заказать звонок
    $("#call__me").iziModal({
        title: 'Обратный звонок',
        subtitle: 'Доставка грузов по всей России',
        borderBottom: false,
        width: 360,
        padding: 15
    });

    $('#form__r').iziModal( {
        title: 'Результат расчета',
        subtitle: 'Доставка грузов по всей России',
        borderBottom: false,
        width: 560,
        padding: 15
    });

    $('.letters__block').slick({
        dots: true,
        infinite: false,
        speed: 300,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1210,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 910,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('.reviews__block').slick({
        dots: true,
        infinite: false,
        speed: 300,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('.letters__block').slickLightbox({
        itemSelector: '.slick-slide > a'
    });
});

$(document).ready(function(){

    $('.phone_us').mask('+7 (000) 000-00-00');

    $('#btn_submit').click(function(){
        // собираем данные с формы
        var user_name 	    = $('#user_name').val();
        var user_phone 	    = $('#user_phone').val();
        // отправляем данные
        $.ajax({
            url: "form/callme.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name": 	user_name,
                "user_phone": 	user_phone
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-call').html(data.result); // выводим ответ сервера
            }
        });
    });

    $('#btn_submit_r').click(function(){
        // собираем данные с формы
        var car_st 	    = $('#car_st').val();
        var city_s 	    = $('#city_s').val();
        var city_e 	    = $('#city_e').val();
        var user_name_r 	    = $('#user_name_r').val();
        var user_phone_r 	    = $('#user_phone_r').val();
        // отправляем данные
        $.ajax({
            url: "form/form_r.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "car_st": 	car_st,
                "city_s": 	city_s,
                "city_e": 	city_e,
                "user_name_r": 	user_name_r,
                "user_phone_r": 	user_phone_r
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-r').html(data.result); // выводим ответ сервера
            }
        });
    });

    $('#btn_submit_q').click(function(){
        // собираем данные с формы
        var user_name_question 	    = $('#user_name_question').val();
        var user_email_question 	    = $('#user_email_question').val();
        var user_phone_question 	    = $('#user_phone_question').val();
        var text_comment_question    = $('#text_comment_question').val();
        // отправляем данные
        $.ajax({
            url: "form/question.php", // куда отправляем
            type: "post", // метод передачи
            dataType: "json", // тип передачи данных
            data: { // что отправляем
                "user_name_question": 	user_name_question,
                "text_comment_question": text_comment_question,
                "user_email_question": user_email_question,
                "user_phone_question": 	user_phone_question
            },
            // после получения ответа сервера
            success: function(data){
                $('.messages-q').html(data.result); // выводим ответ сервера
            }
        });
    });

});